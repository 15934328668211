export default () => {
    try {
        window.addEventListener("error", function (e) {
            if (e.message === 'ResizeObserver loop limit exceeded') return;

            let stack = e.error !== null && e.error.stack ? e.error.stack : "";
            let error = {
                type: e.type,
                message: e.message,
                filename: e.filename,
                line: e.lineno,
                stack: stack,
                url: location.href
            }; 
            $.ajax({
                type: "POST",
                url: location.origin,
                headers: {
                    'X-APP-Authorization': btoa(btoa('BBB-7614f875-8423-4f20-a674-d7cf3096290e')),
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                data: error
            });
        });
    } catch (e) { }

    console.error = function () { };
    console.warn = function () { };
}