import pace from 'pace-js';
/* -------------------------------------------------------------------------- */
/* Loader Options                                                             */
/* -------------------------------------------------------------------------- */
export default () => {
    window.paceOptions = {
        ajax: true,
        document: false,
        eventLag: false,
        elements: {
            selectors: ['#preloader']
        }
    };
    pace.start();
    
    pace.on('done', function () {
        $('#preloader,#preloader #preloader-icon').addClass("isdone");
        // $('.loading').addClass("isdone");
        setTimeout(() => {
            document.body.classList.replace('pace-running', 'pace-done')
            $('.pace.pace-inactive').css({ display: 'none' })
        }, 1000)
        setTimeout(() => {
            $('#preloader').css({ display: 'none' })
        }, 1700)
    });

}