import AppClasses from '../classes.json';

export default function () {
    return {
        AppClasses: AppClasses,
        realClass: function (cl = String | Array,returnArray = false) {
            if (cl == '' || cl == null || (Array.isArray(cl) && cl.length == 0)) return cl;
            let output = '';

            if (!Array.isArray(cl)) {
                cl = cl.trim();
                cl = cl.split(" ");
            }

            cl.forEach(c => {
                c = c.trim();
                if (Object.hasOwnProperty.call(AppClasses, c)) {
                    output += ' ' + AppClasses[`${c}`];
                } else {
                    output += ' ' + c;
                }
            });

            return returnArray ? output.trim().split(" ") : output.trim();
        }
    }
}